import Vue from "vue";
import Router from "vue-router";
import fb from "@/main";
import store from "@/store";

import Home from "@/views/Home.vue";
import Login from "./views/Login.vue";
import Exercises from "./views/Exercises.vue";
import Round from "./views/Round.vue";
import WorkoutBuilder from "./views/WorkoutBuilder.vue";
import Schedule from "./views/Schedule.vue";
import Members from "./views/Members.vue";
import Member from "./views/Member.vue";
import CheckIn from "./views/CheckIn.vue";
import RecentCheckIns from "./views/RecentCheckIns.vue";

import NotFound from "./views/NotFound.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { requiresAuth: true },
    },
    {
      path: "/exercises",
      name: "exercises",
      component: Exercises,
      meta: { requiresAuth: true },
    },
    {
      path: "/round/:id",
      name: "round",
      component: Round,
      meta: { requiresAuth: true },
    },
    {
      path: "/workout-builder",
      name: "workoutbuilder",
      component: WorkoutBuilder,
      meta: { requiresAuth: true }
    },
    {
      path: "/schedule",
      name: "schedule",
      component: Schedule,
      meta: { requiresAuth: true }
    },
    {
      path: "/check-in",
      name: "checkin",
      component: CheckIn,
      meta: { requiresAuth: true }
    },
    {
      path: "/members",
      name: "members",
      component: Members,
      meta: { requiresAuth: true }
    },
    {
      path: "/member/:id",
      name: "member",
      component: Member,
      meta: { requiresAuth: true }
    },
    {
      path: "/recent-checkins",
      name: "recentcheckins",
      component: RecentCheckIns,
      meta: { requiresAuth: true }
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "*",
      component: NotFound,
      meta: { requiresAuth: true }
    }
  ],
});
router.beforeEach(async (to, from, next) => {
  // Check for requiresAuth guard
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if NO logged userd
    if (!fb.auth.currentUser) {
      next({
        path: "/login",
      });
    } else if(store.getters['user']) {
        // don't need o fetch user, already cached
        next()
      } else {
        const { uid } = await fb.auth.currentUser;
        const doc = await fb.db.collection('users').doc(uid).get()
        store.commit("cacheUser", doc.data())
        next();
      }

  } else {
    // Proceed to route
    next();
  }
});
export default router;
