<template>
  <div id="app">
    <v-app>
      <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        color="primary"
        dark
        v-if="privateRoute()"
      >
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <img
          src="https://www.nolabelsboxing.com/assets/img/logos/logo.png"
          width="160"
          class="d-inline-block ml-3"
          @click="goHome"
          style="cursor: pointer"
        /><span
          style="opacity: 0.3; margin-top: 27px; margin-left: 4px"
          class="caption"
        >
          v0.03</span
        ><span style="font-size: 20px; margin-top: 3px" class="ml-3"></span>
        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-avatar :size="36" class="white lighten-1">
                <v-icon color="primary">person</v-icon>
              </v-avatar>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title
                ><v-btn color="primary" text @click="signout"
                  >signout</v-btn
                ></v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
      <v-navigation-drawer
        v-model="drawer"
        app
        color="secondary"
        dark
        clipped
        v-if="privateRoute()"
        width="180"
      >
        <v-list dense>
          <template v-for="(item, i) in navItems">
            <v-layout v-if="item.heading" :key="i" align-center>
              <v-flex xs12>
                <v-subheader v-if="item.heading">{{
                  item.heading
                }}</v-subheader>
              </v-flex>
            </v-layout>
            <v-divider
              v-else-if="item.divider"
              :key="i"
              dark
              class="my-2 mt-0"
            ></v-divider>
            <v-list-item v-else :key="i" :to="item.link" :target="item.target">
              <v-list-item-action class="mr-3">
                <v-icon small>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="grey--text">
                  {{ item.text
                  }}<span v-if="item.new"
                    ><v-chip small class="ml-3" color="green" dark
                      >new</v-chip
                    ></span
                  >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>

      <v-main class="content">
        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "@/main";
export default {
  name: "App",
  data: () => ({
    dialog: false,
    drawer: null,
    navItems: [
      {
        icon: "home",
        text: "Home",
        link: "/",
      },
      {
        icon: "fitness_center",
        text: "Exercises",
        link: "/exercises",
      },
      {
        icon: "build",
        text: "Workout Builder",
        link: "/workout-builder",
      },
      {
        icon: "event",
        text: "Schedule",
        link: "/schedule",
      },
      { divider: true, role: ["Yard Manager"] },
      {
        icon: "person",
        text: "Members",
        link: "/members",
      },
      {
        icon: "person",
        text: "Recent Check-ins",
        link: "/recent-checkins",
      },
      { divider: true, role: ["Yard Manager"] },
      {
        icon: "badge",
        text: "Check-in",
        link: "/check-in",
      },
    ],
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  created() {},
  methods: {
    privateRoute() {
      if (["round","checkin"].includes(this.$route.name)) {
        return false;
      } else {
        return this.$route.meta.requiresAuth;
      }
    },
    signout: async function () {
      try {
        await fb.auth.signOut();
        this.$router.push("/login");
      } catch (error) {
        alert(error);
      }
    },
    goHome() {
      if (this.$route.path !== "/") {
        this.$router.push("/");
      } else {
        return;
      }
    },
  },
};
</script>

<style>
body {
  background: rgb(226, 226, 226);
}
.theme--light.v-data-table tbody tr:hover:not(.v-data-table__expand-row) {
  background: none !important;
}
</style>
