<template>
  <v-container fluid style="max-width: 1680px">
    <v-dialog v-model="checkInDialog" fullscreen>
      <v-card v-if="currentUser">
        <v-toolbar dark color="primary">
          <h2>{{ currentUser.name }}</h2>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              checkInDialog = false;
              currentUser = null;
            "
            ><v-icon>close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="mt-4">
          <h3>Hey {{ capitalizedFirstName }}...remember</h3>
          <h3 class="mt-2 headline font-weight-light">
            <p>"{{ generateRandomQuote() }}"</p>
          </h3>
          <h2 class="mt-4">
            <v-btn
              color="success"
              class="mr-4"
              x-large
              block
              @click="checkInMember"
              >Check-in</v-btn
            >
            <!-- 4:00pm-7:30pm (Evening) -->
            <!-- Private Session -->
          </h2>
          <!-- <br /><br /><br />
          <p class="mt-6">
            Check in for another slot? <v-btn color="primary" small text>Click here</v-btn>
          </p> -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary"
      dark
    >
      <img
        src="https://www.nolabelsboxing.com/assets/img/logos/logo.png"
        width="140"
        class="d-inline-block"
      />
    </v-app-bar>
    <v-row>
      <v-col cols="12" class="headline"
        ><v-icon color="primary" large>location_history</v-icon> Check-in</v-col
      >
      <v-col cols="12" sm="12" md="6" class="pt-0">
        <v-autocomplete
          outlined
          clearable
          label="Search Name"
          :items="members"
          prepend-inner-icon="search"
          item-text="fullName"
          v-model="autocompleteValue"
          autocomplete
          return-object
          :loading="loading"
          @change="onChangeAutocomplete"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
      top
      class="snackbar-top-offset"
    >
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import fb from "@/main";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "Home",
  data() {
    return {
      search: "",
      loading: true,
      dataExists: false,
      snack: false,
      snackColor: "",
      snackText: "",
      checkins: [],
      members: [],
      autocompleteValue: null,
      checkInDialog: false,
      currentUser: null,
    };
  },
  async created() {
    this.getMembers();
  },
  computed: {
    ...mapGetters(["user"]),
    capitalizedFirstName() {
      return (
        this.currentUser.firstName.charAt(0).toUpperCase() +
        this.currentUser.firstName.slice(1)
      );
    },
  },
  methods: {
    async getMembers() {
      try {
        const contactsRef = await fb.db
          .collection("contacts")
          .where("tags", "array-contains", "activemember")
          .get();
        const members = [];
        contactsRef.forEach((doc) => {
          members.push({
            id: doc.id,
            fullName: `${doc.data().firstName} ${doc.data().lastName}`,
            ...doc.data(),
          });
        });
        this.members = members;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.error(error.message);
        this.snackColor = "error";
        this.snackText = "Error fetching members";
        this.snack = true;
      }
    },
    async onChangeAutocomplete() {
      if (this.autocompleteValue) {
        const { id } = this.autocompleteValue;
        await this.getMember(id);
        this.checkInDialog = true;
      }
    },
    async getMember(id) {
      // get member from firestore by id
      const ref = await fb.db.collection("contacts").doc(id).get();
      console.log(ref.data());
      this.currentUser = ref.data();
    },
    async checkInMember() {
      // check in member into firestore collection named checkins
      const { id, firstName, lastName, locationId } = this.currentUser;

      // Get the current timestamp using Firestore's server timestamp
      const checkInTime = fb.timestamp;

      // Temporarily use the local time for session determination
      const localTime = new Date();
      const session = this.getSessionType(localTime);

      const checkIn = {
        firstName,
        lastName,
        memberId: id,
        locationId,
        checkInTime,
        session, // Append the session type
      };

      console.log("checkIn", checkIn);

      try {
        await fb.db.collection("checkins").add(checkIn);
      } catch (error) {
        console.error(error.message);
        this.snackColor = "error";
        this.snackText = "Error checking in member";
        this.snack = true;
        return;
      }

      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Successfully Checked in!";
      this.checkInDialog = false;
      this.autocompleteValue = null;
    },
    getSessionType(date) {
      // Convert the date to a moment object
      const momentCheckInTime = moment(date);

      // Get the day of the week (e.g., "monday")
      const day = momentCheckInTime.format("dddd").toLowerCase();

      // Get the time in HH:mm format (e.g., "16:30")
      const time = momentCheckInTime.format("HH:mm");

      const schedule = this.$store.state.workoutSchedule; // Access schedule from Vuex state

      console.log("Schedule:", schedule);
      console.log("Day:", day);
      console.log("Time:", time);

      const matchingSession = schedule.find((slot) => {
        console.log("Slot Day:", slot.day, "Day:", day);
        console.log("Slot StartTime:", slot.startTime, "Time:", time);
        console.log("Slot EndTime:", slot.endTime, "Time:", time);
        return (
          slot.day === day && slot.startTime <= time && slot.endTime >= time
        );
      });

      console.log("Matching Session:", matchingSession);

      return matchingSession ? matchingSession.type : "unknown"; // Return slot.type instead of slot.session
    },
    generateRandomQuote() {
      const quotes = [
        "The pain you feel today will be the strength you feel tomorrow.",
        "It’s not about having time. It’s about making time.",
        "Your only limit is you.",
        "A little progress each day adds up to big results.",
        "Fitness is not about being better than someone else. It’s about being better than you used to be.",
        "The body achieves what the mind believes.",
        "Do something today that your future self will thank you for.",
        "The hardest lift of all is lifting your butt off the couch.",
        "You don’t have to be great to start, but you have to start to be great.",
        "Strength does not come from physical capacity. It comes from an indomitable will. – Mahatma Gandhi",
        "We are what we repeatedly do. Excellence, then, is not an act, but a habit. – Aristotle",
        "The difference between try and triumph is a little umph.",
        "Don’t count the days, make the days count. – Muhammad Ali",
        "If it doesn’t challenge you, it won’t change you.",
        "Strive for progress, not perfection.",
        "Fall in love with taking care of yourself. Mind. Body. Spirit.",
        "Push yourself because no one else is going to do it for you.",
        "The last three or four reps is what makes the muscle grow. – Arnold Schwarzenegger",
        "Success starts with self-discipline.",
        "Motivation is what gets you started. Habit is what keeps you going.",
        "Discipline is the bridge between goals and accomplishment.",
        "Respect your body. It’s the only one you get.",
        "Your body can stand almost anything. It’s your mind that you have to convince.",
        "To give anything less than your best is to sacrifice the gift. – Steve Prefontaine",
        "Life begins at the end of your comfort zone.",
        "You are only one workout away from a good mood.",
        "No matter how slow you go, you are still lapping everybody on the couch.",
        "Fitness is like a relationship. You can’t cheat and expect it to work.",
        "The best project you'll ever work on is you.",
        "Pain is temporary. Pride is forever.",
        "Excuses don’t burn calories.",
        "Don’t wish for a good body, work for it.",
        "The only bad workout is the one that didn’t happen.",
        "When you feel like quitting, think about why you started.",
        "Sweat, smile, and repeat.",
        "Today’s workout is tomorrow’s body.",
        "You don’t get what you wish for. You get what you work for.",
        "Fit is not a destination, it’s a way of life.",
        "Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle.",
        "Be stronger than your excuses.",
        "You can't control what goes on outside, but you CAN control what goes on inside.",
        "It’s not about perfect. It’s about effort.",
        "Fitness is not about being better than someone else... It’s about being better than you used to be.",
        "The only person you are destined to become is the person you decide to be.",
        "Don’t limit your challenges, challenge your limits.",
        "The body achieves what the mind believes.",
        "Every workout is progress.",
        "If it doesn’t challenge you, it doesn’t change you.",
        "The only way to finish is to start.",
      ];

      const randomQuote = quotes[Math.floor(Math.random() * quotes.length)]; // Select a random quote
      return randomQuote;
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
</script>
/* Scoped CSS */
<style scoped>
.snackbar-top-offset {
  top: 50px !important; /* Adjust this value as needed */
}
</style>
