<template>
  <v-container fluid style="max-width: 1680px">
    <v-row>
      <v-col cols="12" class="headline">Members</v-col>
      <v-col cols="12" sm="12" md="6" class="pt-0">
        <v-card>
          <v-card-title class="grey lighten-3 pa-2"
            >Upcoming Trial Workouts
          </v-card-title>
          <v-card-text>
            <v-simple-table dense class="mt-2" v-if="!loading">
              <template v-slot:default>
                <thead>
                  <th class="px-0 pr-1 text-left">Title</th>
                  <th class="px-0 pr-1 text-left">Date</th>
                  <th class="px-0 pr-1 text-left">Time</th>
                </thead>
                <tbody>
                  <tr v-for="event of events" :key="event.id">
                    <td class="px-0 pr-1">
                      {{ event.title.split(" - ")[0]}}
                    </td>
                    <td class="px-0 pr-1">
                      {{
                        event.startTime
                          | moment("dddd - MMM Do")
                      }}
                    </td>
                    <td class="px-0 pr-1">
                      {{
                        event.startTime
                          | moment("h:mm A")
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-progress-linear
              v-else
              indeterminate
              color="primary"
              class="mt-4" />
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col cols="12" sm="12" md="6" class="pt-0">
        <v-card>
          <v-card-title class="grey lighten-3 pa-2">Members </v-card-title>
          <v-card-text>
            <v-simple-table dense class="mt-2">
              <template v-slot:default>
                <thead>
                  <th class="px-0 pr-1 text-left">Name</th>
                  <th class="px-0 pr-1 text-left">Status</th>
                </thead>
                <tbody>
                  <tr v-for="member of members" :key="member.id">
                    <td class="px-0 pr-1">
                      {{ member.firstName }} {{ member.lastName }}
                    </td>
                    <td class="px-0 pr-1">{{ member.membershipStatus }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "@/main";
export default {
  name: "Home",
  data() {
    return {
      search: "",
      loading: true,
      snack: false,
      snackColor: "",
      snackText: "",
      events: [],
    };
  },
  async created() {
    this.upComingEvents();
    // this.getSubscriptions();
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async upComingEvents() {
      // get events from oncall functions
      try {
        const events = await fb.functions.httpsCallable("getUpcomingEvents")();
        this.events = events.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error fetching events";
      }
    },
    async getSubscriptions() {
      // get events from oncall functions
      try {
        const data = await fb.functions.httpsCallable("getSubscriptions")();
        console.log('subs',data);
        // this.getSubscriptions = data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error fetching events";
      }
    },
  },
};
</script>
