<template>
  <v-container grid-list-xl>
    <!-- CUSTOMER INFO -->
    <v-row>
      <v-col
        v-for="station in stations"
        :key="station"
        cols="12"
        sm="12"
        md="4"
      >
        <v-card height="100" :to="`/round/${station}`" color="secondary" dark>
          <v-card-text class="pt-4 px-1 text-center">
            <h1 class="font-weight-light pt-5">Round {{ station }}</h1>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      search: "",
      loading: true,
      dataExists: false,
      snack: false,
      snackColor: "",
      snackText: "",
      estDate: null,
      stations: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      headers: [
        {
          text: "JOB NAME",
          value: "job.jobName",
        },
      ],
    };
  },
  async created() {},
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {},
};
</script>
