<template>
  <v-container fluid style="max-width: 1680px">
    <v-row>
      <v-col cols="12" class="headline">{{ capitalizedName }} Check-ins</v-col>
      <v-col cols="12" sm="12" md="6" class="pt-0">
        <v-timeline
          align-top
          dense
          v-for="checkin of checkins"
          :key="checkin.id"
        >
          <v-timeline-item color="teal lighten-3">
            <v-row class="pt-1">
              <v-col cols="12">
                <strong>{{ checkin.checkInTime.toDate() | moment('dddd - MMMM D') }}</strong>
                <div class="text-caption text-uppercase">{{ checkin.checkInTime.toDate() | moment('h:mm A') }} - {{checkin.session}}</div>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "@/main";
export default {
  name: "Home",
  data() {
    return {
      search: "",
      loading: true,
      snack: false,
      snackColor: "",
      snackText: "",
      events: [],
      checkins: [],
    };
  },
  async created() {
    await this.getCheckinsByMember();
  },
  computed: {
    ...mapGetters(["user"]),
    capitalizedName() {
      if (this.checkins.length > 0) {
        return (
          this.checkins[0].firstName.charAt(0).toUpperCase() +
          this.checkins[0].firstName.slice(1) +
          " " +
          this.checkins[0].lastName.charAt(0).toUpperCase() +
          this.checkins[0].lastName.slice(1)
        );
      } else {
        return "";
      }
    },
  },
  methods: {
    getCheckinsByMember() {
      this.loading = true;
      fb.db
        .collection("checkins")
        .where("memberId", "==", this.$route.params.id)
        .orderBy("checkInTime", "desc")
        .limit(20)
        .get()
        .then((querySnapshot) => {
          this.checkins = [];
          querySnapshot.forEach((doc) => {
            let checkin = doc.data();
            checkin.id = doc.id;
            this.checkins.push(checkin);
          });
          console.log("Check-ins: ", this.checkins);
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error getting check-ins: ", error);
          this.snackColor = "error";
          this.snackText = "Error getting check-ins";
          this.snack = true;
          this.loading = false;
        });
    },
  },
};
</script>
