<template>
  <v-container fluid style="max-width: 1680px">
        <!-- new dialog -->
    <v-dialog v-model="newDialog" width="1000">
      <v-card>
        <v-card-title primary-title color="primary" dark>
          NEW EXERCISE
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                  <video
                  height="400PX"
                  autoplay="autoplay"
                  muted
                  loop
                  v-if="fileData"
                >
                  <source
                  :src="fileData.videoUrl"
                    type="video/mp4"
                  />
                </video>
              </v-col>
              <v-col cols="12" sm="12" md="8">
                Prefill: <v-btn
                  @click="preFill('boxing')"
                  class="mr-1 mb-1 ml-1"
                  outlined
                  text
                  x-small
                >Boxing</v-btn>
                <v-btn
                  @click="preFill('strength')"
                  class="mb-1"
                  outlined
                  text
                  x-small
                >Strength</v-btn>
                <v-text-field
                  label="Name"
                  v-model="newExercise.name"
                  class="mb-1"
                ></v-text-field>
                <v-select
                  label="Rounds"
                  :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                  v-model="newExercise.rounds"
                  chips
                  multiple
                  small-chips
                  class="mb-1"
                ></v-select>
                <v-select
                  label="Equipment"
                  :items="equipmentList"
                  v-model="newExercise.equipment"
                  chips
                  multiple
                  small-chips
                  class="mb-1"
                  compact
                ></v-select>
                <v-select
                  :items="muscleGroupList"
                  v-model="newExercise.muscleGroups"
                  chips
                  multiple
                  small-chips
                  class="mb-1"
                  label="Muscle Groups"
                  compact
                ></v-select>
                <h3 class="mt-2">Select Video</h3>
                <v-file-input
                        max-width="100%"
                        hint="Add exercise video"
                        persistent-hint
                        v-model="file"
                        @change="videoLoaded"
                        accept="video/mp4,video/x-m4v,video/*"
                        dense
                      ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="newDialog = false">cancel</v-btn>
          <v-btn color="primary" @click="save(newExercise)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end new dialog -->
    <!-- edit dialog -->
    <v-dialog v-model="editDialog" width="1000">
      <v-card v-if="currentItem">
        <v-card-title primary-title color="primary" dark>
          {{ currentItem.name }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <video
                  height="400PX"
                  autoplay="autoplay"
                  muted
                  :key="currentItem.id"
                  loop
                >
                  <source
                    :src="currentItem.videoUrl"
                    type="video/mp4"
                    :key="currentItem.id"
                  />
                </video>
              </v-col>
              <v-col cols="12" sm="12" md="8">
                <v-text-field
                  label="Name"
                  v-model="currentItem.name"
                  class="mb-1"
                ></v-text-field>
                <v-select
                  label="Rounds"
                  :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                  v-model="currentItem.rounds"
                  chips
                  multiple
                  small-chips
                  class="mb-1"
                ></v-select>
                <v-select
                  label="Equipment"
                  :items="equipmentList"
                  v-model="currentItem.equipment"
                  chips
                  multiple
                  small-chips
                  class="mb-1"
                ></v-select>
                <v-select
                  :items="muscleGroupList"
                  v-model="currentItem.muscleGroups"
                  chips
                  multiple
                  small-chips
                  class="mb-1"
                  label="Muscle Groups"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="editDialog = false">cancel</v-btn>
          <v-btn color="primary" @click="saveEdits(currentItem)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end edit dialog -->
    <!-- view dialog -->
    <v-dialog v-model="viewDialog" width="800">
      <v-card v-if="currentItem">
        <v-card-title primary-title color="primary" dark>
          {{ currentItem.name }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="4">
                <video
                  height="400PX"
                  autoplay="autoplay"
                  muted
                  :key="currentItem.id"
                  loop
                >
                  <source
                    :src="currentItem.videoUrl"
                    type="video/mp4"
                    :key="currentItem.id"
                  />
                </video>
              </v-col>
              <v-col cols="12" sm="12" md="8">
                <v-simple-table dense class="mt-2">
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="px-0 pr-1" width="120">Rounds</td>
                        <td class="px-0 pr-1">
                          {{ currentItem.rounds && currentItem.rounds.join(", ") }}
                        </td>
                      </tr>
                      <tr>
                        <td class="px-0 pr-1" width="120">Equipment</td>
                        <td class="px-0 pr-1">
                          {{ currentItem.equipment && currentItem.equipment.join(", ") }}
                        </td>
                      </tr>
                      <tr>
                        <td class="px-0 pr-1" width="120">Muscle Groups</td>
                        <td class="px-0 pr-1">
                          {{ currentItem.muscleGroups && currentItem.muscleGroups.join(", ") }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end view dialog -->
    <v-row wrap>
      <v-col cols="12" class="headline"
        >Exercise Library
        <v-btn small color="primary" class="ml-3" @click="openDialog({}, 'newDialog')"
          ><v-icon small left>add</v-icon>NEW</v-btn
        ></v-col
      >
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="exercises"
            class="elevation-1"
            :loading="loading"
            :search="search"
            sort-by="createDate"
            sort-desc
            :items-per-page="ipp"
            :page="page"
            :footer-props="{
              itemsPerPageOptions: [12, 25, 100, -1],
            }"
          >
            <template v-slot:top>
              <v-toolbar flat color="white" class="pt-1">
                <v-text-field
                  v-model="search"
                  dense
                  label="Search"
                  hide-details
                  outlined
                  clearable
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item.rounds="{ item }">
              <v-chip
                v-for="(r, i) in item.rounds"
                :key="i"
                small
                class="mr-1 mb-1"
                color="primary"
                >{{ r }}</v-chip
              >
            </template>
            <template v-slot:item.equipment="{ item }">
              <v-chip
                v-for="e in item.equipment"
                :key="e"
                small
                class="mr-1 mb-1"
                >{{ e }}</v-chip
              >
            </template>
            <template v-slot:item.view="{ item }">
              <v-btn
                color="primary"
                small
                outlined
                text
                class="mr-1"
                @click="openDialog(item, 'viewDialog')"
                >view</v-btn
              >
              <v-btn
                color="primary"
                small
                outlined
                text
                @click="openDialog(item, 'editDialog')"
                >edit</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Exercises",
  data() {
    return {
      newExercise: {
        name: "",
        rounds: [],
        equipment: [],
        muscleGroups: []
      },
      exercises: [],
      viewDialog: false,
      editDialog: false,
      newDialog: false,
      search: "",
      loading: true,
      dataExists: false,
      snack: false,
      snackColor: "",
      snackText: "",
      page: 1,
      ipp: 12,
      currentItem: null,
      file: null,
      fileData: [],
      headers: [
        {
          text: "Name",
          value: "name",
          width: 260,
        },
        { text: "Rounds", value: "rounds", width: 320 },
        { text: "Equipment", value: "equipment", width: 360 },
        // { text: "Muscle Groups", value: "muscleGroups" },
        { text: "", value: "view", width: 200, align: "right" },
      ],
      equipmentList: [
        "Heavy Bag",
        "Water Bag",
        "No Equipment",
        "Double-End Bag",
        "Dumbbells",
        "Bench",
        "Resistance Band",
        "Barbell",
        "TRX",
        "Dumbells",
        "Kettlebells",
        "Tire",
        "Air Bike",
        "Ski Erg",
        "Medicine Ball",
        "Plyo Box",
        "Kettlebell",
        "Sled",
        "Battle Rope",
        "Gliders",
      ],
      muscleGroupList: [
        "Shoulders",
        "Legs",
        "Core",
        "Biceps",
        "Triceps",
        "Chest",
        "Arms",
        "Back",
        "Quads",
        "Hips",
        "Glutes",
        "Hamstrings",
        "Adductors",
        "Compound",
        "Upper Back",
        "Delts",
      ],
    };
  },
  async created() {
    await this.getExercises();
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    videoLoaded() {
      console.log(this.file);
      if (this.file) {
          const fr = new FileReader();
          fr.readAsDataURL(this.file);
          console.log('here');
          fr.addEventListener("load", () => {
            this.fileData = {
              name: this.file.name,
              videoUrl: fr.result,
              video: this.file,
            }
          });
        this.newExercise.name = this.file.name.replace('.mp4', '').replace(/-/g, ', ').replace(/(\b\w)/g, (match) => match.toUpperCase());
      } else {
        this.fileData = null;
      }
    },
    preFill(type) {
      if (type === "boxing") {
        this.newExercise.rounds = [1,6,8];
        this.newExercise.equipment = ['Heavy Bag', 'Water Bag', 'No Equipment'];
      } else if (type === 'strength') {
        this.newExercise.rounds = [2,3,4,5,10];
      }
    },
    async getExercises() {
      this.loading = true;
      this.exercises = await this.$store.dispatch("getExercises");
      this.loading = false;
    },
    openDialog(item, dialog) {
      this.currentItem = { ...item };
      this[dialog] = true;
    },
    async saveEdits(currentItem)   {
      this.editDialog = false;
      await this.$store.dispatch("saveExercise", currentItem);
      await this.getExercises();
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Changes saved";
    },
    async save(newExercise) {
      try {
        this.newDialog = false;
        newExercise.file = this.fileData.video;
        newExercise.id = this.fileData.name.replace('.mp4', '');
        await this.$store.dispatch("createExercise", newExercise);
        this.newExercise = {
          name: "",
          rounds: [],
          equipment: [],
          muscleGroups: []
        }
        this.newDialog = false;
        await this.getExercises();
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Exercise added";
      } catch (error) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error Adding Exercise";
      }
    },
  },
};
</script>
