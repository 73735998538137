import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import vuetify from './plugins/vuetify';
import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import firebaseConfig from './firebaseConfig'
Vue.use(require('vue-moment'));
firebase.initializeApp(firebaseConfig)

const fb = {
  auth: firebase.auth(),
  db: firebase.firestore(),
  timestamp: firebase.firestore.FieldValue.serverTimestamp(),
  functions: firebase.functions(),
  storage: firebase.storage()
}


if (location.hostname === "localhost") {
  console.log('------------------')
  console.log('%c DEV MODE! ', 'background: #183757; color: #ffff');
  console.log('------------------')
  // fb.db.useEmulator("localhost",9999);
  // fb.functions.useEmulator("localhost",5001);
}

Vue.config.productionTip = false

let app;
fb.auth.onAuthStateChanged(function() {
  if (!app) {
    /* eslint-disable no-new */
    app = new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
});

export default fb;