<template>
  <v-container fluid style="max-width: 1680px">
    <v-row>
      <v-col cols="12" class="headline">Workout Schedule</v-col>
      <v-col cols="12" sm="12" md="6" class="pt-0">
        <div
          class="green ml-1 mr-2"
          style="
            border-radius: 50%;
            display: inline-block;
            height: 8px;
            width: 8px;
          "
        ></div>
        <span class="caption">Scheduled Workout</span>
        <v-date-picker
          elevation="2"
          full-width
          class="mt-1"
          v-model="date"
          :event-color="(date) => (date[9] % 2 ? 'red' : 'yellow')"
          :events="functionEvents"
        ></v-date-picker>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-btn
          v-if="workout"
          class="mt-4"
          outlined
          color="primary"
          :to="`/workout-builder?date=${date}`"
          >EDIT Workout</v-btn
        >
        <div
          v-for="(ex, round) in workout"
          :key="round"
          class="mb-4 grey lighten-5"
        >
          <div>
            <v-container class="mt-4">
              <v-row>
                <v-col cols="12" sm="12">
                  <h3>ROUND {{ round }}</h3>
                  <div v-if="workout[round][0]">
                    {{ workout[round][0].name }} -
                    <span class="green--text font-weight-black">{{workout[round][0].instructions}}</span>
                  </div>
                  <div v-if="workout[round][1]">
                    {{ workout[round][1].name }} -
                    <span class="green--text font-weight-black"
                      >{{ workout[round][1].instructions}}</span>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "@/main";
export default {
  name: "Home",
  data() {
    return {
      search: "",
      loading: true,
      dataExists: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      snack: false,
      snackColor: "",
      snackText: "",
      scheduledDays: [],
      allWorkouts: [],
    };
  },
  async created() {
    const res = await fb.db.collection("scheduledWorkouts").get();
    const workouts = [];
    res.forEach((r) => {
      workouts.push({ id: r.id, ...r.data() });
    });
    this.allWorkouts = workouts;
    this.scheduledDays = workouts.map((w) => w.date);
  },
  computed: {
    ...mapGetters(["user"]),
    workout: function () {
      const found = this.allWorkouts.find((aw) => aw.date == this.date);
      return found ? found.workout : null;
    },
  },
  methods: {
    functionEvents(date) {
      if (this.scheduledDays.includes(date)) return ["green"];
      return false;
    },
  },
};
</script>
