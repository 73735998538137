<template>
  <v-container fluid style="max-width: 1680px">
    <v-row>
      <v-col cols="12" class="headline">Member Check-Ins</v-col>
      <v-col cols="12" sm="12" md="6" class="pt-0">
            <v-simple-table dense class="mt-2" v-if="!loading">
              <template v-slot:default>
                <thead>
                  <th class="px-0 pr-1 text-left"></th>
                  <th class="px-0 pr-1 text-left">Date</th>
                  <th class="px-0 pr-1 text-left">Time</th>
                  <th class="px-0 pr-1 text-left"></th>
                </thead>
                <tbody>
                  <tr v-for="checkin of checkins" :key="checkin.id">
                    <td class="px-0 pr-1">
                      <v-btn
                        :to="{
                          name: 'member',
                          params: { id: checkin.memberId },
                        }"
                        color="primary"
                        class="link-style"
                        small
                        text
                      >{{ checkin.firstName }} {{ checkin.lastName }}
                      </v-btn>
                    </td>
                    <td class="px-0 pr-1">
                      {{ checkin.checkInTime.toDate() | moment("MM/DD/YYYY") }}
                    </td>
                    <td class="px-0 pr-1">
                      {{ checkin.checkInTime.toDate() | moment("hh:mm A") }}
                    </td>
                    <td class="px-0 pr-1">
                      <v-btn
                        color="error"
                        icon
                        small
                        @click="confirmDeleteCheckin(checkin)"
                      >
                        <v-icon small>delete_forever</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-progress-linear
              v-else
              indeterminate
              color="primary"
              class="mt-4"
            />
      </v-col>
    </v-row>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirm Deletion</v-card-title>
        <v-card-text v-if="selectedCheckin">
          Are you sure you want to delete the check-in for
          <strong
            >{{ selectedCheckin.firstName }}
            {{ selectedCheckin.lastName }}</strong
          >?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn
            color="red darken-1"
            text
            @click="deleteCheckin(selectedCheckin.id)"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "@/main";

export default {
  name: "Home",
  data() {
    return {
      loading: true,
      snack: false,
      snackColor: "",
      snackText: "",
      checkins: [],
      dialog: false, // Control for the dialog visibility
      selectedCheckin: null, // The checkin selected for deletion confirmation
      unsubscribe: null, // To store the unsubscribe function for the snapshot listener
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    startListeningToCheckins() {
      this.unsubscribe = fb.db
        .collection("checkins")
        .orderBy("checkInTime", "desc")
        .limit(30)
        .onSnapshot(
          (snapshot) => {
            const checkins = [];
            snapshot.forEach((doc) => {
              checkins.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.checkins = checkins;
            this.loading = false;
          },
          (error) => {
            this.snack = true;
            this.snackColor = "error";
            this.snackText = "Error getting member checkins";
            console.error("Error getting member checkins: ", error);
          }
        );
    },
    confirmDeleteCheckin(checkin) {
      this.selectedCheckin = checkin;
      this.dialog = true;
    },
    async deleteCheckin(id) {
      try {
        await fb.db.collection("checkins").doc(id).delete();
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Checkin deleted";
        this.dialog = false;
      } catch (error) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error deleting checkin";
        console.error("Error deleting checkin: ", error);
      }
    },
  },
  created() {
    this.startListeningToCheckins();
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
};
</script>
<style scoped>
.link-style {
  background: none!important;
  border: none;
  padding: 0 5px !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: none;
  cursor: pointer;
}

.link-style:hover {
  background-color: none;
  background: none;
}
</style>